import youtube from '@/Assets/Images/platforms/youtube.svg?raw'
import facebook from '@/Assets/Images/platforms/facebook.svg?raw'
import twitch from '@/Assets/Images/platforms/twitch.svg?raw'
import mixcloud from '@/Assets/Images/platforms/mixcloud.svg?raw'
import rumble from '@/Assets/Images/platforms/rumble.svg?raw'
import kick from '@/Assets/Images/platforms/kick.svg?raw'

export const VOLUME = {
  MIN: 0,
  MAX: 100,
  VIDEO: 70,
  VIDEO_DEFAULT: null,
  AUDIO_DEFAULT: 70,
  SECONDARY_AUDIO_DEFAULT: 30,
}

export const PLATFORMS = [
  {
    value: 'youtube',
    label: 'YouTube',
    icon: youtube,
    keyPlaceholder: 'a1b2-c3d4-e5f6-g7h8',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-start-a-permanent-247-youtube-live-stream-1tzuej4/',
      label: 'permanent 24/7 YouTube',
    },
  },
  {
    value: 'twitch',
    label: 'Twitch',
    icon: twitch,
    keyPlaceholder: 'live_12345abcde_fghijk78910lmnop...',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-start-a-247-twitch-live-stream-1nn24y9/',
      label: 'permanent 24/7 Twitch',
    },
  },
  {
    value: 'facebook',
    label: 'Facebook',
    icon: facebook,
    keyPlaceholder: 'abc1-234d-56ef-789g-0hij',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-start-a-livestream-on-facebook-8ti95e/',
      label: 'Facebook',
    },
  },
  {
    value: 'mixcloud',
    label: 'Mixcloud',
    icon: mixcloud,
    keyPlaceholder: 'mx1234abcd-5678-efgh-ijkl-9mnopq',
    helpdesk: {
      url: null,
      label: null,
    },
  },
  {
    value: 'rumble',
    label: 'Rumble',
    icon: rumble,
    keyPlaceholder: 'a1b2-c3d4-e5f6-g7h8...',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-make-a-247-live-stream-on-rumble-1nfdwbm/',
      label: 'permanent 24/7 Rumble',
    },
  },
  {
    value: 'kick',
    label: 'Kick',
    icon: kick,
    keyPlaceholder: 'a1b2-c3d4-e5f6-g7h8...',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-make-a-247-live-stream-on-kick-1m898rp/ ',
      label: 'permanent 24/7 Kick',
    },
  },
  {
    value: 'custom',
    label: 'Other',
    helpText: 'Custom RTMP',
    keyPlaceholder: 'a1b2-c3d4-e5f6-g7h8...',
    helpdesk: {
      url: 'https://help.upstream.so/en/article/how-to-live-stream-247-on-custom-rtmp-server-o8pt61/',
      label: 'permanent 24/7 Custom RTMP',
    },
  },
]

export const TOASTIFY = {
  success: 'Successfully saved',
  error: 'Something went wrong',
}

export const ANIMATIONS = {
  random: {
    label: 'Random',
    value: 'random',
  },
  fade: {
    label: 'Fade out',
    value: 'fade',
  },
  slide: {
    label: 'Slide horizontally',
    value: 'slide',
  },
  scale: {
    label: 'Scale out',
    value: 'scale',
  },
  swipeAway: {
    label: 'Swipe away',
    value: 'swipeAway',
  },
}

export const VISUALIZER_DEFAULTS = [
  { key: 'volume', label: 'Volume', value: 0, type: null },
  {
    key: 'gradient',
    label: 'Gradient',
    value: 'classic',
    type: 'select',
    options: [
      { label: 'Classic', value: 'classic' },
      { label: 'Orange Red', value: 'orangered' },
      { label: 'Prism', value: 'prism' },
      { label: 'Rainbow', value: 'rainbow' },
      { label: 'Steel Blue', value: 'steelblue' },
      { label: 'Custom colors', value: 'customGradient' },
    ],
  },
  {
    key: 'gradientColors',
    label: 'Gradient colors',
    value: ['rgb(6, 255, 180)', 'rgb(80, 4, 241)'],
  },
  {
    key: 'mode',
    label: 'Mode',
    value: 5,
    type: 'select',
    options: [1, 2, 3, 4, 5, 6, 7, 8, 10],
  },
  {
    key: 'barSpace',
    label: 'Bar space',
    value: 0.1,
    type: 'number',
    min: 0,
    max: 0.9,
    step: 0.1,
  },
  { key: 'lumiBars', label: 'Lumi bars', value: false, type: 'checkbox' },
  { key: 'weightingFilter', label: 'Weighting filter', value: 'D', type: null },
  {
    key: 'fillAlpha',
    value: 1,
    label: 'Fill alpha channel',
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
  },
  {
    key: 'channelLayout',
    label: 'Channel layout',
    value: 'single',
    type: null,
  },
  {
    key: 'linearAmplitude',
    label: 'Linear amplitude',
    value: false,
    type: null,
  },
  { key: 'linearBoost', label: 'Linear boost', value: 1, type: null },
  {
    key: 'lineWidth',
    label: 'Line width',
    value: 0,
    type: 'number',
    min: 0,
    max: 100,
  },
  {
    key: 'maxFreq',
    label: 'Maximum frequency',
    value: 16000,
    type: 'number',
    min: 0,
    max: 20000,
  },
  {
    key: 'minFreq',
    label: 'Minimum frequency',
    value: 20,
    type: 'number',
    min: 1,
    max: 20000,
  },
  { key: 'peakLine', label: 'Peak line', value: false, type: 'checkbox' },
  { key: 'showPeaks', label: 'Show peaks', value: true, type: 'checkbox' },
  {
    key: 'frequencyScale',
    label: 'Frequency scale',
    value: 'log',
    type: 'select',
    options: ['log', 'linear', 'bark', 'mel'],
  },
  { key: 'ledBars', label: 'LED bars', value: false, type: 'checkbox' },
  {
    key: 'reflexRatio',
    label: 'Reflex ratio',
    value: 0,
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
  },
  {
    key: 'reflexAlpha',
    label: 'Reflex alpha channel',
    value: 0.15,
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
  },
  {
    key: 'colorMode',
    label: 'Color mode',
    value: 'gradient',
    type: 'select',
    options: ['gradient', 'bar-index', 'bar-level'],
  },
  { key: 'radial', label: 'Radial', value: false, type: 'checkbox' },
  {
    key: 'maxDecibels',
    label: 'Maximum decibels',
    value: -25,
    type: 'number',
    min: -100,
    max: 0,
    step: 1,
  },
  {
    key: 'radialInvert',
    label: 'Radial invert',
    value: false,
    type: 'checkbox',
  },
  {
    key: 'spinSpeed',
    label: 'Spin speed',
    value: 0,
    type: 'number',
    min: 0,
    max: 100,
    step: 1,
  },
  { key: 'outlineBars', label: 'Outline bars', value: false, type: 'checkbox' },
  {
    key: 'reflexBright',
    label: 'Reflex brightness',
    value: 1,
    type: 'number',
    min: 0,
    max: 1,
    step: 0.1,
  },
  { key: 'reflexFit', label: 'Reflex fit', value: true, type: 'checkbox' },
  { key: 'ansiBands', label: 'ANSI bands', value: false, type: 'checkbox' },
  { key: 'overlay', label: 'Overlay', value: true, type: null },
  {
    key: 'showBgColor',
    label: 'Show background color',
    value: false,
    type: null,
  },
  { key: 'showScaleX', label: 'Show scale X', value: false, type: null },
  { key: 'showScaleY', label: 'Show scale Y', value: false, type: null },
  {
    key: 'maxFPS',
    label: 'Maximum FPS',
    value: 30,
    type: 'number',
    steps: 1,
    min: 0,
    max: 30,
  },
]

export const UUID_LENGTH = 36

export const MAX_TIMEOUT_DURATION = 2147483647

export const VIRTUAL_LIST_KEEPS_DIVISOR = 30
