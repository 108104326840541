<script>
import AuthLayout from '@/Layouts/AuthLayout.vue'

export default {
  layout: AuthLayout,
}
</script>

<script setup>
import { useForm } from '@inertiajs/inertia-vue3'
import useRoute from '@/Composables/useRoute'
import OauthLogin from '@/Pages/Auth/OauthLogin.vue'

const route = useRoute

defineProps({
  message: String,
})

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const queryParams = new URLSearchParams(window.location.search)
if (queryParams.get('email')) {
  form.email = queryParams.get('email')
}

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password'),
  })
}
</script>

<template>
  <Head title="Login" />
  <div class="lg:h-screen flex lg:pl-20 pt-10 lg:pt-0">
    <div class="my-auto px-5 w-full">
      <Form v-model="form" name="login" @submit.prevent="submit">
        <h2 class="dark:text-white text-2xl">Sign In</h2>

        <div class="mt-8">
          <Input
            data-cy="email-input"
            name="email"
            class="w-full"
            placeholder="Email"
            type="email"
            autocomplete="username"
            required
          />
          <Input
            data-cy="password-input"
            name="password"
            class="w-full mt-4"
            placeholder="Password"
            type="password"
            autocomplete="current-password"
            required
          />
        </div>
        <div
          class="flex text-neutral-600 dark:text-neutral-400 text-xs sm:text-sm"
        >
          <div class="flex items-center mr-auto">
            <Group>
              <Checkbox
                data-cy="check-box"
                name="remember"
                label="Remember me"
              />
            </Group>
          </div>
          <Link
            :href="route('password.request')"
            class="mt-4 hover:text-grape"
            data-cy="forgot-password-router"
          >
            Forgot your password?
          </Link>
        </div>
        <div class="flex flex-col md:flex-row mt-8 text-left">
          <Submit
            data-cy="submit"
            label="Login"
            class="mb-2 md:mb-0 md:mr-2 flex-1 md:flex-none"
          />

          <OauthLogin />
        </div>

        <Link
          class="dark:text-white mt-4 pb-4 block underline hover:no-underline"
          :href="route('register')"
        >
          Not a member? Sign up now
        </Link>
      </Form>
    </div>
  </div>
</template>
