import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import { createInertiaApp, Head, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import {
  Checkbox,
  File,
  Form,
  Group,
  Input,
  Radio,
  Select,
  Submit,
} from '@/Components/Shared/Form'
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue'
import Icon from '@/Components/Shared/Icon.vue'
import VueKinesis from 'vue-kinesis'

const appName = 'Upstream.so'
const pinia = createPinia()

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    )
    page.default.layout = page.default.layout || AuthenticatedLayout

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    app
      .use(plugin)
      .use(VueKinesis)
      .use(pinia)
      .component('Checkbox', Checkbox)
      .component('Form', Form)
      .component('File', File)
      .component('Group', Group)
      .component('Input', Input)
      .component('Radio', Radio)
      .component('Select', Select)
      .component('Submit', Submit)
      .component('Link', Link)
      .component('Head', Head)
      .component('Icon', Icon)
      .mount(el)

    return app
  },
})

InertiaProgress.init({ color: '#4B5563' })
