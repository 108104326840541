<script setup>
import ApplicationLogo from '@/Components/Shared/ApplicationLogo.vue'
import { onMounted } from 'vue'
import dom from '@left4code/tw-starter/dist/js/dom'
import { useMediaQuery } from '@vueuse/core'

// Fixes video showing in full screen on mobile. Can't be fixed with CSS.
const isLargeScreen = useMediaQuery('(min-width: 1024px)')

defineProps({
  title: String,
  description: String,
})

onMounted(() => {
  dom('html').addClass('admin-panel')
  dom('body').removeClass('main').removeClass('error-page').addClass('login')
})
</script>

<template>
  <div class="container relative z-10 sm:px-10">
    <Head title="Login" />
    <div class="lg:grid grid-cols-2 gap-4">
      <div class="flex flex-col relative">
        <div
          v-if="isLargeScreen"
          class="fixed left-0 h-screen -z-10 w-1/2 overflow-hidden bg-main"
        >
          <video
            muted
            autoplay
            loop
            src="/images/login_loop.mp4"
            class="absolute top-0 left-0 w-full h-full object-cover mix-blend-overlay opacity-50"
          />
        </div>
        <ApplicationLogo class="flex items-center pt-5 lg:absolute" />
        <div class="my-auto pt-6 lg:pt-0 px-4 lg:pl-8 lg:pr-4">
          <div
            class="lg:text-white dark:text-white font-medium text-opacity-50 text-xl lg:text-2xl leading-tight"
            v-html="title"
          ></div>
          <div
            class="mt-5 text-md lg:text-4xl lg:text-white dark:text-white"
            v-html="description"
          ></div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<style>
.login {
  @apply bg-white dark:bg-grape-900;
}
</style>
